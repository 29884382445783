import Slider, { Settings } from 'react-slick';
import Image from 'next/image';
import useWindowDimensions from '~/hooks/useWindowDemensions';
import { useRef } from 'react';

interface IPromotionCarouselProps {
  promotions: BannerPageItemTypes[];
}

export const PromotionCarousel: React.FC<IPromotionCarouselProps> = ({ promotions }) => {
  const clickableRef = useRef(true);
  const sliderRef = useRef<Slider>(null);

  const { width } = useWindowDimensions();

  const settings: Settings = {
    dots: true,
    infinite: promotions.length > 1 ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    dotsClass: 'slick-dots promotionCarousel__dots',
    customPaging: () => <span className="promotionCarousel__dot" />,
    pauseOnHover: true,
    draggable: true,
    swipeToSlide: true,
  };

  const imageCurrent = (side?: BannerPageItemTypes) => {
    if (width > 991) return side?.data.imageDesktop || '';
    if (width >= 577 && width <= 991) return side?.data.imageTablet || '';
    return side?.data.imageMobile || '';
  };

  const handleClick = (event: MouseEvent) => {
    if (!clickableRef.current) {
      event.stopPropagation();
      event.preventDefault();
    }
    clickableRef.current = true;
  };

  const swipeEvent = () => {
    if (sliderRef?.current?.innerSlider?.list) {
      sliderRef.current.innerSlider.list.onclick = handleClick;
      clickableRef.current = false;
    }
  };

  return (
    <div className="promotionCarousel">
      <Slider
        ref={sliderRef}
        {...settings}
        {...{ swipeEvent }}
        className="promotionCarousel__slider"
      >
        {promotions.map((p, index) =>
          p?.data?.link ? (
            <div
              key={`promotion-${index.toString()}`}
              className="promotionCarousel__item promotionCarousel__item-link"
            >
              <a
                href={p.data.link}
                target="_blank"
                rel="noreferrer"
                className="promotionCarousel__item-img"
              >
                <Image
                  alt={`promotion-${index.toString()}`}
                  src={imageCurrent(p)}
                  width={1920}
                  height={1080}
                  objectFit="contain"
                  style={{
                    width: 'auto',
                    height: '100%',
                  }}
                />
              </a>
            </div>
          ) : (
            <div key={`promotion-${index.toString()}`} className="promotionCarousel__item">
              <span className="promotionCarousel__item-img">
                <Image
                  alt={`promotion-${index.toString()}`}
                  src={imageCurrent(p)}
                  width={1920}
                  height={1080}
                  objectFit="contain"
                  style={{
                    width: 'auto',
                    height: '100%',
                  }}
                />
              </span>
            </div>
          )
        )}
      </Slider>
    </div>
  );
};
