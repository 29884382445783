import { Stack, Title } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { Announcements } from '~/components/Announcements/Announcements';
import { setPageOptions } from '~/components/AppLayout/AppLayout';
import { FeedLayout } from '~/components/AppLayout/FeedLayout';
import { IsClient } from '~/components/IsClient/IsClient';
import { MasonryContainer } from '~/components/MasonryColumns/MasonryContainer';
import { Meta } from '~/components/Meta/Meta';
import { ModelsInfinite } from '~/components/Model/Infinite/ModelsInfinite';
import { useModelQueryParams } from '~/components/Model/model.utils';
import { env } from '~/env/client.mjs';
import { useFiltersContext } from '~/providers/FiltersProvider';
import { PeriodMode } from '~/server/schema/base.schema';
import { useSystem } from '~/store/system.store';
import { getModelPageService } from '../api/extends/pages';

export default function ModelsPage() {
  const { set, view: queryView, ...queryFilters } = useModelQueryParams();
  const { username, query } = queryFilters;
  const periodMode = query ? ('stats' as PeriodMode) : undefined;
  const { setFilters, earlyAccess } = useFiltersContext((state) => ({
    setFilters: state.setModelFilters,
    earlyAccess: state.models.earlyAccess,
  }));

  const staticAll = useSystem((state) => state.staticAll);

  const modelSlug =
    staticAll?.find((page) => page.pageData.code === 'MODEL_PAGE')?.pageData.slug || '';

  const { data: modelsData } = useQuery(['models-page-data', modelSlug], () => getModelPageService(modelSlug), { enabled: !!modelSlug});

  if (periodMode) queryFilters.periodMode = periodMode;

  return (
    <>
      <Meta
        seoData={modelsData?.seoData}
        links={[{ href: `${env.NEXT_PUBLIC_BASE_URL}/models`, rel: 'canonical' }]}
        ogData={modelsData?.ogData}
      />

      <MasonryContainer>
        <Announcements />
        {username && typeof username === 'string' && <Title>Models by {username}</Title>}
        <Stack spacing="xs">
          <IsClient>
            {/* TODO: Bring back something similar in the future once we start selling spots. */}
            {/* <EarlyAccessHighlight /> */}
            {/* <Group spacing="xs" noWrap>
              <Button
                variant={earlyAccess ? 'filled' : 'outline'}
                color="success.5"
                onClick={() => setFilters({ earlyAccess: !earlyAccess })}
                compact
                leftIcon={<IconClock size={16} />}
              >
                Early Access
              </Button>
              <CategoryTags />
            </Group> */}
            <ModelsInfinite filters={queryFilters} showEof showAds />
          </IsClient>
        </Stack>
      </MasonryContainer>
    </>
  );
}

setPageOptions(ModelsPage, { innerLayout: FeedLayout });
